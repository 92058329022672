<style scoped>
.is-acknowledge{
    background-color:#E7F5F5;
}
.is-acknowledge:hover{
    background-color:#E7F5F5 ;
}
</style>
<template>
    <v-container>
        <v-container>
        
        </v-container>
        <ComponentTableNotification/>
        <!-- <v-container v-if="!api.isLoading && data!=null">
            <v-row no-gutters>
                <v-col class="mx-auto my-6">
                    <v-card>
                        <v-container>
                            <v-data-table
                            :search="searchResult"
                            :items="data"
                            :headers="dataHeaders"
                            dense
                            >
                                <template v-slot:top>
                                    <v-toolbar flat class="text-h4">
                                        Notifications
                                    </v-toolbar>
                                    <v-container>
                                        <v-toolbar flat>
                                            <v-text-field
                                            dense
                                            outlined
                                            clearable
                                            v-model="searchResult" 
                                            label="Search">
                                            </v-text-field>
                                        </v-toolbar>
                                    </v-container>
                                </template>
                                
                                <template v-slot:item.read_at="{item}">
                                    <v-icon color="green" v-if="item.read_at!=null">
                                        mdi-checkbox-marked-circle-outline
                                    </v-icon>
                                    <v-icon color="yellow">
    
                                    </v-icon>
                                </template>
                                <template v-slot:item = "{item}">
                                    <tr :class="(item.read_at!=null)?'':'is-acknowledge'" @click="readNotification(data[data.indexOf(item)])">
                                       <td> 
                                            <span>
                                            {{item.created_at.split("T")[0] }}
                                            {{ convertTimeZone(item.created_at).split(" ")[3] }} {{  convertTimeZone(item.created_at).split(" ")[4]}}

                                            </span>
                                        </td> 
                                        <td>
                                            <a @click="readNotification(data[data.indexOf(item)])" class="text-decoration-none">
                                                {{ item.title }}
                                            </a>
                                        </td>
                                        <td>
                                            {{ item.company_name }}
                                        </td>
                                        <td>
                                            {{ item.fname+" "+item.lname }}
                                        </td>
                                    
                                    </tr>
                                    
                                </template>
                            </v-data-table>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container> -->
    </v-container>
</template>

<script>
import ComponentTableNotification from '../../components/table/ComponentTableNotification.vue';

// import PageTaskListUser from '../../views/task/PageTaskListUser.vue'
export default{
    components:{
    ComponentTableNotification
},
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getNotification"){
                this.data = resp.data;
            }
            if(resp.class=="acknowledgeNotification"){
                this.$store.dispatch('fetchCurrentTotalNotification',this.$store.getters.getUserId);
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    data:()=>({
        searchResult:null,
        api:{
        isSucessful:false,
        isLoading :false,
        isError:false,
        error:null,
        url:null,
        },
        data:null,
        dataHeaders:[
            {
                text:'Date',
                value:'created_at',
                align:'start',
            },
            {
                text:'Title',
                value:'title',
            },
            {
                text:'Company',
                value:'company_name',
            },
            {
                text:'Sender',
                value:'fname',
            },
         
        ]
    }),
    methods:{
        fetch(){
            // let fetchNotificationApi = this.fetchNotification();
            let fetchAcknowledgeNotificationApi = this.fetchAcknowledgeNotification();
            this.$axios.all(
                // this.$api.fetch(fetchNotificationApi),
                this.$api.fetch(fetchAcknowledgeNotificationApi),
               
            )
        },
        fetchAcknowledgeNotification() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/notification/acknowledge/"+this.$store.getters.getUserId;
            return tempApi;
        },
        fetchNotification(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/notification/user/"+this.$store.getters.getEmail;
            return tempApi;
        },
        readNotification(notification){
            this.$router.push({name:'NotificationDetail',params:{'id':notification.notification_id}});
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
        },
    }
}
</script>   