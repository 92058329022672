<style scoped>
.is-acknowledge{
    background-color:#E7F5F5;
}
.is-acknowledge:hover{
    background-color:#E7F5F5 ;
}
</style>
<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading && isFirstLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table 
            v-else
            :items="data.data" 
            :lazy="true"
            :loading ="api.isLoading"
            :options.sync="options"
            :items-per-page:sync="options.itemsPerPage"
            :headers="dataHeader"
            :server-items-length="data.total" 
            :sort-desc:sync="options.sortDesc"
            :sort-by.sync="options.sortBy"
            :footer-props="{
                itemsPerPageOptions:[10,20,50],
                showCurrentPage:true,
            }"
            dense
            @update:options="onPaginationUpdate">

            <template v-slot:item = "{item}">
                <tr :class="(item.read_at!=null)?'':'is-acknowledge'" @click="readNotification(data.data[data.data.indexOf(item)])">
                    <td>
                        {{((options.page-1) * options.itemsPerPage)+(data.data.indexOf(item)+1) }}
                    </td>
                    <td> 
                        <span>
                        {{item.created_at.split("T")[0] }}
                        {{ convertTimeZone(item.created_at).split(" ")[3] }} {{  convertTimeZone(item.created_at).split(" ")[4]}}

                        </span>
                    </td> 
                    <td>
                        <a @click="readNotification(data.data[data.data.indexOf(item)])" class="text-decoration-none">
                            {{ item.title }}
                        </a>
                    </td>
                    <td>
                        <span
                            v-if="item.company_id!=33">
                            {{ item.company_name }}
                        </span>
                        <span 
                            v-else>
                            -
                        </span>
                    </td>
                    <td>
                        {{ item.fname+" "+item.lname }}
                    </td>
                </tr>
                
            </template>
            <!-- <template v-slot:item.created_at="{item}">
                {{ convertTimeZone(item.created_at) }}
            </template>
            <template v-slot:item.index="{item}">
                {{((options.page-1) * options.itemsPerPage)+(data.data.indexOf(item)+1) }}
            </template> -->
            <template v-slot:top>
                <v-toolbar flat class="text-h4 pt-4" height="auto">
                    <v-col class="mx-4">
                        <v-row no-gutters>
                            
                            Notification
                        </v-row>
                        <v-row>
    
                            <v-text-field 
                                class="mt-4"
                                dense
                                @change="fetch()"
                                outlined
                                clearable
                                v-model="search"
                                label="Search">
            
                            </v-text-field>
                        </v-row>

                    </v-col>
                </v-toolbar>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        isFirstLoading:true,
        isFirstLoad:true,
        data:null,
        search:null,
        options:{
            itemsPerPage:10,
            page:1,
            sortBy:[],
        },
        dataHeader:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'Date',
                value:'created_at',
                align:'start',
            },
            {
                text:'Title',
                value:'title',
            },
            {
                text:'Company',
                value:'company_name',
            },
            {
                text:'Sender',
                value:'fname',
            },
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getNotificationPaginate"){
                this.data = resp.data;
                this.isFirstLoading = false;

            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchNotificationApi = this.fetchNotification();
            this.$api.fetch(fetchNotificationApi);
        },
        fetchNotification(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/notification/user/paginate/"+this.$store.getters.getEmail+"?page="+this.options.page+"&sortBy="+this.options.sortBy+"&sortDesc="+this.options.sortDesc+"&groupBy="+this.options.groupBy+"&groupDesc="+this.options.groupDesc+"&search="+this.search+'&rowsPerPage='+this.options.itemsPerPage;
            return tempApi;
        },
       
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        onPaginationUpdate(pagination){
            if(this.isFirstLoad){
                this.isFirstLoad = false
            }
            else{
                this.options = this.$_.clone(pagination);
                this.fetch();

            } 
            // Client is asking for different page
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
        },
        readNotification(notification){
            this.$router.push({name:'NotificationDetail',params:{
                'id':notification.notification_id,
            }});
        }

    }
}
</script>